<template>
    <div class="custom-star-rating" :height="getSize" :width="getSize" :viewBox="viewBox" @mousemove="mouseMoving"
        @click="selected">
        <!-- <p class="star-number bold">{{ starId }}</p> -->
        <img :src="imageSrc" alt :width="size" />
    </div>
</template>

<script type="text/javascript">
import _activeDetractor from '@assets/images/rate/active_detractor.png';
import _activePassive from '@assets/images/rate/active_passive.png';
import _activePromoter from '@assets/images/rate/active_promoter.png';
import _inactiveDetractor from '@assets/images/rate/inactive_detractor.png';
import _inactivePassive from '@assets/images/rate/inactive_passive.png';
import _inactivePromoter from '@assets/images/rate/inactive_promoter.png';

export default {
    props: {
        fill: {
            type: Number,
            default: 0
        },
        points: {
            type: Array,
            default() {
                return []
            }
        },
        size: {
            type: Number,
            default: 40
        },
        starId: {
            type: Number,
            required: true
        },
        activeColor: {
            type: String,
            required: true
        },
        inactiveColor: {
            type: String,
            required: true
        },
        borderColor: {
            type: String,
            default: '#000'
        },
        borderWidth: {
            type: Number,
            default: 0
        },
        roundedCorners: {
            type: Boolean,
            default: false
        },
        rtl: {
            type: Boolean,
            default: false
        },
        glow: {
            type: Number,
            default: 0
        },
        glowColor: {
            type: String,
            required: false
        }
    },
    created() {
        this.starPoints = (this.points.length) ? this.points : this.starPoints
        this.calculatePoints()
        this.grad = this.getRandomId()
        this.glowId = this.getRandomId()
    },
    computed: {
        imageSrc() {
            //  This section has been comented to get all the happy face in rate scale.                  
            //   if(this.starId >= 1 && this.starId <= 7){
            //     return this.isActive ? this.activeDetractor : this.inactiveDetractor;
            //   }
            //   else if (this.starId >= 8 && this.starId <= 9) {
            //     return this.isActive ? this.activePassive : this.inactivePassive;
            //   }
            //   else {
            //     return this.isActive ? this.activePromoter : this.inactivePromoter;
            //   }
            let imgStore = null;
            switch (this.starId) {
                case 1:
                imgStore = this.isActive ? this.img1 : this.img1Inactive;
                    break;
                case 2:
                imgStore = this.isActive ? this.img2 : this.img2Inactive;
                    break;
                case 3:
                imgStore = this.isActive ? this.img3 : this.img3Inactive;
                    break;
                case 4:
                imgStore = this.isActive ? this.img4 : this.img4Inactive;
                    break;
                case 5:
                imgStore = this.isActive ? this.img5 : this.img5Inactive;
                    break;
                case 6:
                imgStore = this.isActive ? this.img6 : this.img6Inactive;
                    break;
                case 7:
                imgStore = this.isActive ? this.img7 : this.img7Inactive;
                    break;
                case 8:
                imgStore = this.isActive ? this.img8 : this.img8Inactive;
                    break;
                case 9:
                imgStore = this.isActive ? this.img9 : this.img9Inactive;
                    break;
                case 10:
                imgStore = this.isActive ? this.img10 : this.img10Inactive;
                    break;
            }
            return imgStore;

        },
        isActive() {
            return this.getFillRaw > 0;
        },
        starPointsToString() {
            return this.starPoints.join(',')
        },
        getGradId() {
            return 'url(#' + this.grad + ')'
        },
        getSize() {
            // Adjust star size when rounded corners are set with no border, to account for the 'hidden' border
            const size = (this.roundedCorners && this.borderWidth <= 0) ? parseInt(this.size) - parseInt(this.border) : this.size
            return parseInt(size) + parseInt(this.border)
        },
        getFill() {
            return (this.rtl) ? 100 - this.fill + '%' : this.fill + '%'
        },
        getFillRaw() {
            return (this.rtl) ? 100 - this.fill : this.fill
        },
        border() {
            return (this.roundedCorners && this.borderWidth <= 0) ? 6 : this.borderWidth
        },
        getBorderColor() {
            if (this.roundedCorners && this.borderWidth <= 0) {
                // create a hidden border
                return (this.fill <= 0) ? this.inactiveColor : this.activeColor
            }

            return this.borderColor
        },
        maxSize() {
            return this.starPoints.reduce(function (a, b) {
                return Math.max(a, b)
            })
        },
        viewBox() {
            return '0 0 ' + this.maxSize + ' ' + this.maxSize
        }
    },
    methods: {
        mouseMoving($event) {
            this.$emit('star-mouse-move', {
                event: $event,
                position: this.getPosition($event),
                id: this.starId
            })
        },
        getPosition($event) {
            // calculate position in percentage.
            var starWidth = (92 / 100) * this.size
            const offset = (this.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1)
            var position = Math.round((100 / starWidth) * offset)

            return Math.min(position, 100)
        },
        selected($event) {
            this.$emit('star-selected', {
                id: this.starId,
                position: this.getPosition($event)
            })
        },
        getRandomId() {
            return this.getRandomCrypto().toString(36).substring(7)
        },
        calculatePoints() {
            this.starPoints = this.starPoints.map((point) => {
                return ((this.size / this.maxSize) * point) + (this.border * 1.5)
            })
        },
    },
    data() {
        return {
            starPoints: [19.8, 2.2, 6.6, 43.56, 39.6, 17.16, 0, 17.16, 33, 43.56],
            grad: '',
            glowId: '',


            activeDetractor: _activeDetractor,
            activePassive: _activePassive,
            activePromoter: _activePromoter,
            inactiveDetractor: _inactiveDetractor,
            inactivePassive: _inactivePassive,
            inactivePromoter: _inactivePromoter,

            img1: require('@assets/images/rate/active_numbers/1.png'),
            img2: require('@assets/images/rate/active_numbers/2.png'),
            img3: require('@assets/images/rate/active_numbers/3.png'),
            img4: require('@assets/images/rate/active_numbers/4.png'),
            img5: require('@assets/images/rate/active_numbers/5.png'),
            img6: require('@assets/images/rate/active_numbers/6.png'),
            img7: require('@assets/images/rate/active_numbers/7.png'),
            img8: require('@assets/images/rate/active_numbers/8.png'),
            img9: require('@assets/images/rate/active_numbers/9.png'),
            img10: require('@assets/images/rate/active_numbers/10.png'),
            img1Inactive: require('@assets/images/rate/inactive_numbers/1.png'),
            img2Inactive: require('@assets/images/rate/inactive_numbers/2.png'),
            img3Inactive: require('@assets/images/rate/inactive_numbers/3.png'),
            img4Inactive: require('@assets/images/rate/inactive_numbers/4.png'),
            img5Inactive: require('@assets/images/rate/inactive_numbers/5.png'),
            img6Inactive: require('@assets/images/rate/inactive_numbers/6.png'),
            img7Inactive: require('@assets/images/rate/inactive_numbers/7.png'),
            img8Inactive: require('@assets/images/rate/inactive_numbers/8.png'),
            img9Inactive: require('@assets/images/rate/inactive_numbers/9.png'),
            img10Inactive: require('@assets/images/rate/inactive_numbers/10.png')
        }
    }
}
</script>

<style scoped>
.custom-star-rating {
    overflow: visible !important;
}

.star-number {
    text-align: center;
}
</style>