<template>
  <div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userName }}</p>
      <small>{{lastSession}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer v-step-15">

      <div class="con-img ml-3">
        <div v-if="hasName" class="rounded-full shadow-md cursor-pointer block user-initials">
          {{ getInitals }}
        </div>
        <img v-else :src="photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown" v-if="!loggedOut">
        <ul style="min-width: 10rem">
          <div v-if="isApplicant">
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/solicitante/perfil').catch(() => {})">
              <span>Mi perfil</span>
            </li>
            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
              <a class="text-black hover:text-white" href="https://soporte.redgirasol.com/support/home" target="_blank">
                ¿Tienes dudas?
              </a>
            </li>
          </div>
          <div v-else-if="isSupplier">
            <li
              v-if="hasFinished"
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/proveedor/perfil/editar').catch(() => {})">
              <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Mi perfil</span>
            </li>
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/proveedor/perfil/cuenta').catch(() => {})">
              <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Cuenta</span>
            </li>
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/proveedor/perfil/seguridad').catch(() => {})">
              <feather-icon icon="LockIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Seguridad</span>
            </li>
          </div>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="showFeedback">
            <feather-icon icon="ThumbsUpIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Danos tu opinión</span>
          </li>

          <template v-if="showSwitchAccountButton">
            <vs-divider class="m-1" />
            <li
              class="flex py-2 pl-2 pr-3 cursor-pointer hover:bg-primary hover:text-white"
              @click="switchSessionRequest">
              <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Cambiar sesión</span>
            </li>
          </template>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 pl-2 pr-3 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Cerrar sesión</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateHelper from '../../../../components/mixins/dateHelper';
const lastStepPM = "pm_mex_20_merchant_contract";
const lastStepPF = "pf_mex_18_merchant_contract";
export default {
  mixins: [ dateHelper],
  data () {
    return {
      loggedOut: false,
      supplier_avatar: require('@assets/images/perfil/supplier_avatar.png'),
      supplier_avatar_red: require('@assets/images/perfil/rg_rojo-03.png'),
      female_avatar: "https://cdn.redgirasol.com/green/perfiles/mujer.jpg",
      male_avatar: "https://cdn.redgirasol.com/green/perfiles/hombre.jpg",
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    userName: function () {
      return (this.user === null) ? "-" : this.user.full_name;
    },
    lastSession : function () {
      let ss = "Último acceso: ";
      if(this.user != null && this.user.last_login != null && this.user.last_login != ''){
        ss += this.sessionFormat(this.user.last_login);
      }
      else {
        ss += "-";
        return "Esta es tu primera vez en RedGirasol";
      }
      return ss;
    },
    photoURL(){
      if(this.isSupplierOrAgent){
        return "https://cdn.redgirasol.com/green/perfiles/hombre-gorra.jpg";
      } else if(this.isApplicant) {
        if(this.user.avatar_info){
          return this.user.avatar_info === 1 ? this.male_avatar : this.female_avatar;
        }
        else {
          return this.male_avatar;
        }
      } else {
        return this.supplier_avatar_red;
      }
    },
    isSupplier(){
      return this.$acl.check('isSupplier');
    },
    isApplicant(){
      return this.$acl.check('isApplicant');
    },
    isInvestor(){
      return this.$acl.check('isInvestor');
    },
    isSupplierOrAgent(){
      return this.$acl.check('isSupplierOrAgent');
    },
    showSwitchAccountButton(){
      return this.HasMultipleRoles;
    },
    hasFinished() {
      return (this.SupplierOnboardingStep == lastStepPM || this.SupplierOnboardingStep == lastStepPF) && this.SupplierVerifiedDate != null
    },
    hasName() {
      return (this.user != null) && this.user.full_name != null && this.user.full_name != "";
    },
    getInitals() {
      if (this.hasName) {
        const splitedName = this.user.full_name.split(" ");
        return `${splitedName[0][0]}${splitedName[splitedName.length-1][0]}`
      } else {
        return "--"
      }
    }
  },
  methods: {
    async logout () {
      this.showFullPageLoader();
      this.loggedOut = true;
      await this.timeout(600);
      await this.executeAppLogout();
    },
    async switchSessionRequest() {
      this.$store.commit("TOGGLE_IS_SWITCH_ACCOUNT_POPUP_ACTIVE", true);
    },
    showFeedback(){
      this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
    },
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>

<style>

.user-initials {
  width: 40px;
  height: 40px;
  background-color: #28de18;
  color: white;
  font-size: 1rem;
  padding-top: 11px;
}

</style>
