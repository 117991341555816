<template>
  <div id="feedback-popup">
    <vs-popup class="feedback-popup" title="¡Te escuchamos!" :active.sync="isFeedbackPopupActive">
      <div class="vx-row">
        <div class="vx-col w-full mb-4">
          <h6>{{formPromptIndication}}</h6>
        </div>

        <!-- <div class="vx-col w-full mt-3 mb-5">
          <vs-alert class="h-full" color="warning">
            <span>Para resolver tus dudas o ayudarte con algún inconveniente, escríbenos a través del chat de la
              plataforma o en cualquiera de nuestras redes sociales.</span>
          </vs-alert>
        </div> -->

        <div class="vx-col w-full mb-5" v-if="onSaveError">
          <vs-alert class="h-full" color="danger">
            <span>{{formMssg}}</span>
          </vs-alert>
        </div>

        <div class="vx-col w-full mb-5">
          <!-- Tipo de feedback -->
          <label class="vs-input--label">¿Sobre qué te gustaría hablar?</label>
          <v-select
            class="vs-custom w-full"
            v-model.lazy="feed.feedback_type"
            data-vv-name="feedback_type"
            v-validate="'required'"
            :options="feedbackOptionsForSelect"
            :reduce="label => label.id"
            :clearable="false"
            :searchable="false"
            placeholder="Seleccione una opción"
          >
          </v-select>
          <v-error v-if="hasError('feedback_type')" :error="errorText('feedback_type')"  />
        </div>
      </div>

      <div class="vx-col w-full mb-6">
        <vx-textarea class="w-full mb-0"
          label="Ingresa tus comentarios:"
          v-model.lazy="feed.feedback"
          data-vv-name="feedback"
          v-validate="'required'"
          :success="isSuccess('feedback')"
          placeholder="(Requerido)"
          />
          <span v-if="hasError('feedback')">
            <div class="con-text-validation span-text-validation-danger v-enter-to" style="height: 20px;">
              <span class="span-text-validation">{{errorText('feedback')}}</span>
            </div>
          </span>
      </div>

      <div class="vx-col w-full mb-6">
        <label>¿Qué tan probable es que nos recomiendes?</label>
        <feedback-rate
          class="mt-3"
          :rtl="$vs.rtl"
          v-model="boundRating"
          :showRating="false"
          :starSize="40"
          :maxRating="10">
          </feedback-rate>

          <span v-if="invalidRate && sent">
            <span class="con-text-validation span-text-validation-danger v-enter-to" style="height: 20px;">
              <span class="span-text-validation">Por favor seleccione un valor en la escala</span>
            </span>
          </span>
      </div>

      <div class="vx-col w-full mb-4">
        <p class="black">Para resolver tus dudas o ayudarte con algún inconveniente, escríbenos a través del chat de la plataforma.</p>
      </div>

      <!-- Save Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" type="border" @click="send()">Enviar comentarios</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>

import FeedbackRate from './rate/FeedbackRate'

const feedbackOptionsDefs = [
  { id: 'general_feedback', label: 'Comentarios generales' },
  { id: 'feature_request', label: 'Sugerencias y nuevas funcionalidades' },
  { id: 'service_issue', label: 'Mejoras a servicios y funcionalidades' },
];

export default {
  name: "feedback-popup",
  components: {
    FeedbackRate
  },
  data() {
    return {
      onSaveError: false,
      sent: false,
      loading: false,
      failed: false,
      formMssg: null,
      formPromptIndication: "En la Red queremos conocer tu opinión para seguir creciendo juntos.",

      boundRating: null,

      feedbackOptionsForSelect: feedbackOptionsDefs,
      feed: {
        feedback_type: null,
        feedback: null,
        url: null,
        user_id: null,
        nps: null,
        app: 'suppliers'
      }
    }
  },
  computed: {
    isFeedbackPopupActive: {
      get() {
        return this.$store.state.isFeedbackPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_FEEDBACK_POPUP_ACTIVE", val);
      }
    },
    invalidRate() {
      return (this.boundRating == null || this.boundRating < 1);
    }
  },
  watch: {
    isFeedbackPopupActive : function(val){
      // saber cuando se cierra o se abre el modal para reiniciar los valores
      if(val){
        this.resetForm();
      }
    },
    loading: function(){
      if(this.loading) {
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  methods: {
    async send(){
      this.sent = true;
      let res = await this.$validator.validateAll();
      if(!res){
        this.onSaveError = true;
        this.formMssg = "Debe llenar todos los campos";
        return;
      }

      // validar que el usuario haya seleccionado una opcion en el NPS
      if(this.invalidRate){
        this.onSaveError = true;
        this.formMssg = "Debe llenar todos los campos";
        return;
      }
      this.feed.user_id = this.UserId;
      this.feed.url = this.$route.fullPath;
      this.feed.nps = this.boundRating;

      this.loading = true;

      try {
        this.injectAccountMetadataToPayload(this.feed);
        await axios.post('/api/v1/forms/postAppFeedback', this.feed);
        this.resetForm();
        this.isFeedbackPopupActive = false;

        this.successNotif(
          "Operación exitosa", 
          "Tus comentarios han sido enviados exitosamente. ¡Agradecemos tu valiosa retroalimentación!",
          8000
        );
      }
      catch (error) {
        this.failedOperationNotif();
      }

      this.loading = false;
    },
    resetForm(){
      this.boundRating = null
      this.feed.feedback_type = null
      this.feed.feedback = null
      this.feed.nps = null
      this.onSaveError = false
      this.formMssg = null
      this.sent = false

      if(this.UserRole === "investor"){
        this.feed.app = "investors";
      }
      else if(this.UserRole === "client"){
        this.feed.app = "applicants";
      }
      else {
        this.feed.app = "suppliers";
      }
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  },
};
</script>