<template functional>
  <footer class="the-footer flex-wrap justify-between">
    <template v-if="!props.is_program">
      <div class="vx-row">
        <div class="vx-col w-full mb-5 footer-col px-6">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="sm:w-full md:w-1/3 lg:w-1/5 mr-auto" style="width:100%;float:left" >
                <img src="https://cdn.redgirasol.com/green/LOGOS/redgirasol.png" width="200px" class="mr-auto" style="float:left" alt="logo">
              </div>
              <div class="vx-col sm:w-full md:w-1/3 lg:w-1/5 mr-auto mt-3" style="float:left;">
                <div class="social-container" style="margin: initial !important;">
                  <div class="social-item">
                    <a
                      href="https://www.facebook.com/redgirasol/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <image-from-source path="/img/facebook-w.png" style="width:20px;" />
                    </a>
                  </div>
                  <div class="social-item">
                    <a href="https://twitter.com/redgirasol" target="_blank" rel="noopener noreferrer">
                      <image-from-source path="/img/twitter-w.png" style="width:20px;" />
                    </a>
                  </div>
                  <div class="social-item">
                    <a
                      href="https://www.instagram.com/redgirasol/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <image-from-source path="/img/instagram-w.png" style="width:20px;" />
                    </a>
                  </div>

                  <div class="social-item">
                    <a
                      href="https://www.linkedin.com/company/10346607/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <image-from-source path="/img/linkedin-w.png" style="width:20px;" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-full">
              <p class="contact-label text-justify mb-3 pt-4">
                Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo en términos de la Ley para Regular las Instituciones de Tecnología Financiera, 
                es una sociedad autorizada, regulada y supervisada por las autoridades financieras. Número de autorización: <a class="router-link-customize" href="http://www.dof.gob.mx/nota_detalle.php?codigo=5651691&fecha=11/05/2022" target="_blank" rel="noopener noreferrer">P137/2021</a>.<br><br>

                Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos de los clientes que 
                sean utilizados en las operaciones que celebren con las ITF o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas 
                por las ITF o por algún cliente frente a otro, en virtud de las operaciones que celebren.  <br><br>
                
                <!-- Tasa de interés anual y fija de 10.9% a 27.9% sin IVA, sujeto a evaluación y calificación. Ejemplo
                representativo: monto de crédito por $118,560.00, con tasa anual de 15.9% y plazo de 60 meses. Monto
                total a pagar: $184,681.26. <strong>CAT PROMEDIO: 27.71% sin IVA</strong>, para fines informativos y
                de comparación exclusivamente. Calculado el 30 de abril de 2022. -->
                <!-- <span v-html='props.footer_text'></span> -->
              </p>  
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div class="vx-row pt-5 pb-4">
        <div class="vx-col lg:w-1/2 md:w-1/3 w-full mb-2">
          <p class="copyright copyright-rg">© Red Girasol S.A.P.I. de C.V., Institución de Financiamiento Colectivo | {{props.year}} | Todos los derechos reservados</p>
        </div>

        <div class="vx-col w-full lg:w-1/4 md:w-1/3 w-1/2">
          <div class="partners-container">
            <!-- <div class="partner-item">
              <a
                href="http://www.afico.org/plataformas.html"
                class="rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                <image-from-source
                  path="/img/aficob.png"
                  style="width:110px !important; margin-top:10px;"
                />
              </a>
            </div>

            <div class="partner-item">
              <a
                href="https://anes.org.mx/index.php/socios/"
                class="rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                <image-from-source path="/img/ANES-01.png" style="width:30px !important;" />
              </a>
            </div>

            <div class="partner-item">
              <a
                href="https://www.circulodecredito.com.mx/"
                class="rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                <image-from-source path="/img/CIRCULOCREDITO-01.png" style="width:50px !important;" />
              </a>
            </div> -->

            
            <div class="partner-item">
              <a
                href="https://www.redgirasol.com/buro-entidades-financieras"
                class="rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                <image-from-source path="/green/LOGOS/buro_entidades_financieras.png" style="width:30px !important;" />
              </a>
            </div>
          </div>
        </div>
        <div class="vx-col w-full lg:w-1/4 md:w-1/3 w-1/2">
          <p class="copyright copyright-rg"><a style="color: #FFF !important;" target="_blank" href="https://www.redgirasol.com/comisiones" rel="noopener noreferrer">Consulta los Costos y las Comisiones <br> de nuestros productos</a></p>
        </div>
      </div>

    </template>    
    <template v-else>
      <div class="vx-row">
        <div class="vx-col w-full mb-5 footer-col px-6">
          <div class="flex fix-logos">
            <div class="" style="float:left" >
              <img src="https://cdn.redgirasol.com/green/LOGOS/redgirasol.png" width="200px" class="mr-auto" style="float:left; margin-top: 8%;" alt="logo">
            </div>
            <div class="ml-4" style="float:left" >
              <img :src="`https://cdn.redgirasol.com/programs/icons/${props.program_info.logo}`" width="100px" class="mr-auto" style="float:left; background-color: white;" alt="logo">
            </div>
            <div class="ml-4" style="float:left" >
              <img :src="`https://cdn.redgirasol.com/programs/icons/giz-logo.png`" width="150px" class="mr-auto" style="float:left; margin-top: 8%; background-color: white;" alt="logo">
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="vx-row pt-5 pb-4">
        <div style="text-align: left;" class="vx-col w-full mb-2 left">
          <p class="copyright">© Red Girasol S.A.P.I. de C.V., Institución de Financiamiento Colectivo | {{props.year}} | Todos los derechos reservados.</p>
        </div>
        <div style="text-align: justify;" class="vx-col w-full mb-2 left">
          <p class="copyright">© {{props.program_info.legal_info}}</p>
        </div>
        <div style="text-align: left;" class="vx-col w-full mb-2 left">
          <p class="copyright">© Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH | {{props.year}} | Todos los derechos reservados.</p>
        </div>
      </div>

    </template>
  </footer>
</template>

<script>
import alerts from '@mixins/alerts';

export default {
  name: "app-footer",
  mixins: [alerts],
  props: {
    year: {
      type: Number
    },
    footer_text: {
      type: String
    },
    is_program: {
      type: Boolean
      , default: false
    },
    program_info: {
      type: Object
      , default: () => {}
    }

  }
};
</script>

<style>
  .fix-logos{
    max-width: min-content;
  }

  .copyright {
    color: #FFF;
    font-size: 12px;
  }
</style>
