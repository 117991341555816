<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="parentx">

    <vs-sidebar class="v-nav-menu items-no-padding" v-model="isVerticalNavMenuActive" ref="verticalNavMenu"
      default-index="-1" :click-not-close="clickNotClose" :reduce-not-rebound="reduceNotRebound" :parent="parent"
      :hiddenBackground="clickNotClose" :reduce="reduce" v-hammer:swipe="onMenuSwipe">

      <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <!-- Header -->
        <div class="header-sidebar flex items-end justify-between" slot="header">

          <!-- Logo -->
          <router-link tag="div" class="vx-logo cursor-pointer flex items-center" to="" @click.native="goToHome">
            <img class="w-10 mr-4 fill-current" :src="getSmallLogoUrl" alt="Logo small"
              v-show="!isMouseEnter && reduce" />
            <img :src="getLogoUrl" alt="Logo" width="80%" :style="isProgram ? 'width: 100%; height: 3.5rem;' : ''"
              v-show="isMouseEnter || !reduce">
            <!--span class="vx-logo-text text-primary" v-show="isMouseEnter || !reduce" v-if="title">{{ title }}</span-->
          </router-link>
          <!-- /Logo -->

          <!-- Menu Buttons -->
          <div>
            <!-- Close Button -->
            <template v-if="showCloseButton">
              <feather-icon svg-classes="text-primary" icon="XIcon" class="m-0 cursor-pointer"
                @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)" />
            </template>

            <!-- Toggle Buttons -->
            <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon id="btnVNavMenuMinToggler" class="mr-0 cursor-pointer"
                :icon="reduce ? 'ArrowRightIcon' : 'MenuIcon'" svg-classes="stroke-current text-primary"
                @click="toggleReduce(!reduce)" />
            </template>
          </div>
          <!-- /Menu Toggle Buttons -->
        </div>
        <!-- /Header -->

        <!-- Header Shadow -->
        <div class="shadow-bottom" v-show="showShadowBottom" />

        <!-- Menu Items -->
        <component :is="scrollbarTag" ref="verticalNavMenuPs" class="scroll-area-v-nav-menu pt-2" :settings="settings"
          @ps-scroll-y="psSectionScroll" @scroll="psSectionScroll" :key="$vs.rtl">
          <template v-for="(item, index) in menuItemsUpdated">

            <div v-if="item.header && !verticalNavMenuItemsMin && checkConditionedMenu(item.condition)">
              <!-- NAVBAR PARA PROVEEDORES -->
              <template v-if="isSupplierOrAgent">
                <vs-sidebar-group :open="item.isOpen" :title="item.header.toUpperCase()">
                  <v-nav-menu-item v-for="(submenu, sindex) in item.items" :key="`item-${sindex}`" :index="index"
                  :to="submenu.slug !== 'external' ? submenu.url : null" :href="submenu.slug === 'external' ? item.url : null"
                  :icon="submenu.icon" :target="submenu.target" :isDisabled="submenu.isDisabled" :slug="submenu.slug"
                  :class="submenu.step">
                  <span v-show="!verticalNavMenuItemsMin" class="truncate">{{ (submenu.i18n) || submenu.name }}</span>
                  <vs-chip class="ml-auto no-box-shadow" :color="submenu.tagColor"
                    v-if="submenu.tag && (isMouseEnter || !reduce)">{{ submenu.tag }}
                  </vs-chip>
  
                  <!-- DYNAMIC CHIP -->
                  <vs-chip class="ml-auto no-box-shadow" :class="submenu.needPulse ? 'pulse-success' : ''"
                    :color="getDynamicTagData(submenu.dynamicTagColor) || 'primary'"
                    v-if="(submenu.dynamicTagCondition == 'always' || checkDynamicTag(submenu.dynamicTagCondition) == true) && (isMouseEnter || !reduce)">{{
                      getDynamicTagData(submenu.dynamicTagLabel) }}
                  </vs-chip>
  
                  <!-- INFO CHIP -->
                  <vs-chip v-if="submenu.infoTag" class="ml-auto no-box-shadow"
                    :color="getDynamicTagData(submenu.dynamicTagColor) || 'primary'">
                    {{ getDynamicTagData(submenu.infoTag) }}
                  </vs-chip>
                </v-nav-menu-item>
                </vs-sidebar-group>
              </template>
              <div class="span-align" v-else>
                <span class="navigation-header truncate">
                  {{ (item.i18n) || item.header }}
                </span>
              </div>
            </div>

            <template v-else-if="!item.header && checkConditionedMenu(item.condition)">

              <!-- Nav-Item -->
              <v-nav-menu-item v-if="!item.submenu" :key="`item-${index}`" :index="index"
                :to="item.slug !== 'external' ? item.url : null" :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon" :target="item.target" :isDisabled="item.isDisabled" :slug="item.slug"
                :class="item.step">
                <span v-show="!verticalNavMenuItemsMin" class="truncate">{{ (item.i18n) || item.name }}</span>
                <vs-chip class="ml-auto no-box-shadow" :color="item.tagColor"
                  v-if="item.tag && (isMouseEnter || !reduce)">{{ item.tag }}
                </vs-chip>

                <!-- DYNAMIC CHIP -->
                <vs-chip class="ml-auto no-box-shadow" :class="item.needPulse ? 'pulse-success' : ''"
                  :color="getDynamicTagData(item.dynamicTagColor) || 'primary'"
                  v-if="(item.dynamicTagCondition == 'always' || checkDynamicTag(item.dynamicTagCondition) == true) && (isMouseEnter || !reduce)">{{
                    getDynamicTagData(item.dynamicTagLabel) }}
                </vs-chip>

                <!-- INFO CHIP -->
                <vs-chip v-if="item.infoTag" class="ml-auto no-box-shadow"
                  :color="getDynamicTagData(item.dynamicTagColor) || 'primary'">
                  {{ getDynamicTagData(item.infoTag) }}
                </vs-chip>
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group :key="`group-${index}`" :openHover="openGroupHover" :group="item" :groupIndex="index"
                  :open="isGroupActive(item)" />
              </template>
              <!-- /Nav-Group -->
            </template>




          </template>
        </component>
        <!-- /Menu Items -->
      </div>
    </vs-sidebar>

    <!-- Swipe Gesture -->
    <div v-if="!isVerticalNavMenuActive" class="v-nav-menu-swipe-area" v-hammer:swipe="onSwipeAreaSwipe" />
    <!-- /Swipe Gesture -->

  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuGroup from './VerticalNavMenuGroup.vue'
import VNavMenuItem from './VerticalNavMenuItem.vue'
import isotipo from '@assets/images/logo/isotipo.png';
import isotipoRed from '@assets/images/logo/rg_rojo-02.png';
import logo from '@assets/images/logo/logo.png';
import logoBlack from '@assets/images/logo/logo_black.png';
import logoRed from '@assets/images/logo/rg_rojo-01.png';

// const supplierIds = [1379,143,877];
const supplierIds = [622, 493];

export default {
  name: 'v-nav-menu',
  components: {
    VNavMenuGroup,
    VNavMenuItem,
    VuePerfectScrollbar,
  },
  props: {
    logo: { type: String },
    openGroupHover: { type: Boolean, default: false },
    parent: { type: String },
    reduceNotRebound: { type: Boolean, default: true },
    navMenuItems: { type: Array, required: true },
    title: { type: String }
  },
  data: () => ({
    clickNotClose: false, // disable close navMenu on outside click
    isMouseEnter: false,
    reduce: false, // determines if navMenu is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    settings: {      // perfectScrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true
    },
    showShadowBottom: false,
    notesToRead: []

  }),
  computed: {
    getLogoUrl: function () {
      if (this.$store.state.theme == 'light') {
        if (this.isProgram && this.currentProgramData != null) {
          return this.$data.StorageURL + "/programs/icons/" + this.currentProgramData.logo;
        }
        return this.$data.StorageURL + "/green/LOGOS/redgirasol-black-green.png"
      }
      else {
        return this.$data.StorageURL + "/green/LOGOS/redgirasol.png"
      }
    },
    getSmallLogoUrl: function () {
      return "https://cdn.redgirasol.com/green/LOGOS/favicon-88x89.png";
    },
    isGroupActive() {
      return (item) => {
        const path = this.$route.fullPath
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined
        let open = false

        const func = (item) => {
          if (item.submenu) {
            item.submenu.forEach((item) => {
              if (item.url && (path === item.url || routeParent === item.slug)) { open = true } else if (item.submenu) { func(item) }
            })
          }
        }
        func(item)
        return open
      }
    },
    menuItemsUpdated() {
      const clone = this.navMenuItems.slice()

      if (!this.isSupplierOrAgent) {
        for (const [index, item] of this.navMenuItems.entries()) {
          if (item.header && item.items.length && (index || 1)) {
            const i = clone.findIndex(ix => ix.header === item.header)
            for (const [subIndex, subItem] of item.items.entries()) {
              clone.splice(i + 1 + subIndex, 0, subItem)
            }
          }
        }
      } else {
        for (const [index, item] of this.navMenuItems.entries()) {
          if (item.header && item.items.length && (index || 1)) {
            const i = clone.findIndex(ix => ix.header === item.header)
            for (const [subIndex, subItem] of item.items.entries()) {
              if(subItem.slug == "perfil") {
                clone.splice(i + 1 + subIndex, 0, subItem)
                break;
              }
            }
          }
        }
      }

      return clone
    },
    isVerticalNavMenuActive: {
      get() { return this.$store.state.isVerticalNavMenuActive },
      set(val) { this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val) }
    },
    layoutType() { return this.$store.state.mainLayoutType },
    reduceButton: {
      get() { return this.$store.state.reduceButton },
      set(val) { this.$store.commit('TOGGLE_REDUCE_BUTTON', val) }
    },
    isVerticalNavMenuReduced() { return Boolean(this.reduce && this.reduceButton) },
    verticalNavMenuItemsMin() { return this.$store.state.verticalNavMenuItemsMin },
    scrollbarTag() { return this.$store.getters.scrollbarTag },
    windowWidth() { return this.$store.state.windowWidth },

    /* DYNAMIC TAGS */
    check_supplier_profile_status() {
      // RN 3.4.3
      if (this.SupplierUpdateProfileInfo.last_update_profile_requirement_date !== null
        && this.SupplierUpdateProfileInfo.update_profile_verified_date === null) {
        return "PENDIENTE";
      } else {
        let ss = this.SupplierStatus;
        if (ss < 3) {
          return "PENDIENTE";
        }
        else if (ss == 3) {
          return "EN PROCESO";
        }
        else if (ss > 3) {
          return "VERIFICADO"
        }
        else {
          return null;
        }
      }
    },
    check_supplier_profile_status_color() {
      // RN 3.4.3
      if (this.SupplierUpdateProfileInfo.last_update_profile_requirement_date !== null
        && this.SupplierUpdateProfileInfo.update_profile_verified_date === null) {
        return 'danger'
      } else {
        let ss = this.SupplierStatus;
        if (ss < 3) {
          return 'danger';
        }
        else if (ss == 3) {
          return 'warning';
        }
        else if (ss > 3) {
          return 'success'
        }
        else {
          return null;
        }
      }
    },

    supplier_status_less_or_3() {
      return this.SupplierStatus <= 3;
    },
    supplier_status_4_or_more() {
      return this.SupplierStatus >= 4;
    },
    supplier_status_3_or_more() {
      if(this.SupplierCanUseReferalCode) {
        return this.SupplierStatus >= 3;
      } else {
        return false;
      }
    },
    new_impacta_mas_chip() {
      return "¡NUEVO!"
    },
    new_impacta_mas_chip_color() {
      return "#28DE18"
    },
    show_applicant_menu_options() {
      return this.ShowApplicantMenuOptions === true;
    },
    show_pending_profile() {
      if (this.SupplierUpdateProfileInfo !== undefined) {
        return this.supplier_status_less_or_3 || (
          this.SupplierUpdateProfileInfo.last_update_profile_requirement_date !== null
          && this.SupplierUpdateProfileInfo.update_profile_verified_date === null)
      } else {
        return false;
      }
    },
    isProgram() {
      return this.$acl.check('isProgram');
    },
    isProgramSupplier() {
      return this.SupplierOrAgentProgramAccess;
    },
    show_zone_regions() {
      return this.AuxGuardName == "program_zone_manager";
    },
    show_users_admin() {
      return this.AuxGuardName == "program_zone_manager" || this.AuxGuardName == "program_region_manager";
    },
    show_config_module() {
      return this.$acl.check('isProgramCommercialManager') && this.show_users_admin;
    },
    isProgramCommercialManager() {
      return this.$acl.check('isProgramCommercialManager');
    },
    isSupplierOrAgent() {
      return this.$acl.check('isSupplierOrAgent');
    },
    program_notes_to_read() {
      return this.notesToRead.length;
    },
    need_to_show_program_notes_to_read() {
      return this.notesToRead.length > 0;
    }
  },
  watch: {
    '$route'() {
      if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
    },
    reduce(val) {
      const verticalNavMenuWidth = val ? 'reduced' : this.$store.state.windowWidth < 1200 ? 'no-nav-menu' : 'default'
      this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

      setTimeout(function () {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    layoutType() { this.setVerticalNavMenuWidth() },
    reduceButton() { this.setVerticalNavMenuWidth() },
    windowWidth() { this.setVerticalNavMenuWidth() }
  },
  methods: {
    async getNotesToRead() {
      try {
        if (this.isProgramCommercialManager || this.isSupplierOrAgent) {
          const res = await axios.get(`/api/kof/get/notes-to-read/${this.UserId}`);
          this.notesToRead = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async goToHome() {
      if (this.inHome()) {
        return
      }

      if (this.UserRole === "client") {
        await this.$router.push({ name: 'inicioSolicitante' });
      }
      else if (this.UserRole === "investor") {
        await this.$router.push({ name: 'inicioInversionista' });
      }
      else if (this.UserRole === "supplier" || this.UserRole === "supplier_agent") {
        await this.$router.push({ name: 'inicio' });
      } else {
        await this.$router.push({ name: 'inicioKOF' });
      }
    },
    inHome() {
      const rname = this.$route.name;
      return (rname === "inicioSolicitante" || rname === "inicioInversionista" || rname === "inicio" || rname === "inicioKOF");
    },
    checkDynamicTag(condition) {
      return (condition in this && this[condition] == true);
    },
    getDynamicTagData(condition) {
      return this[condition];
    },
    getInfoTagData(label) {
      return this[label];
    },
    /* conditional menu */
    checkConditionedMenu(condition) {
      if (!condition || condition == 'always') {
        return true
      }
      else {
        return (condition in this && this[condition] == true);
      }
    },

    onMenuSwipe(event) {
      if (event.direction === 4 && this.$vs.rtl) {

        // Swipe Right
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false

      } else if (event.direction === 2 && !this.$vs.rtl) {

        // Swipe Left
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      }
    },
    onSwipeAreaSwipe(event) {

      if (event.direction === 4 && !this.$vs.rtl) {

        // Swipe Right
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      } else if (event.direction === 2 && this.$vs.rtl) {

        // Swipe Left
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      }
    },
    psSectionScroll() {
      const scroll_el = this.$refs.verticalNavMenuPs.$el || this.$refs.verticalNavMenuPs
      this.showShadowBottom = scroll_el.scrollTop > 0
    },
    mouseEnter() {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
      this.isMouseEnter = true
    },
    mouseLeave() {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
      this.isMouseEnter = false
    },
    setVerticalNavMenuWidth() {

      if (this.windowWidth > 1200) {
        if (this.layoutType === 'vertical') {

          // Set reduce
          this.reduce = !!this.reduceButton

          // Open NavMenu
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

          // Set Menu Items Only Icon Mode
          const verticalNavMenuItemsMin = !!(this.reduceButton && !this.isMouseEnter)
          this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

          // Menu Action buttons
          this.clickNotClose = true
          this.showCloseButton = false

          const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? 'reduced' : 'default'
          this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

          return
        }
      }

      // Close NavMenu
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      // Reduce button
      if (this.reduceButton) this.reduce = false

      // Menu Action buttons
      this.showCloseButton = true
      this.clickNotClose = false

      // Update NavMenu Width
      this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      // Remove Only Icon in Menu
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)


      // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
      // if (this.windowWidth < 1200) {

      //   // Close NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      //   // Reduce button
      //   if (this.reduceButton) this.reduce = false

      //   // Menu Action buttons
      //   this.showCloseButton = true
      //   this.clickNotClose   = false

      //   // Update NavMenu Width
      //   this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      //   // Remove Only Icon in Menu
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // } else {

      //   // Set reduce
      //   this.reduce = this.reduceButton ? true : false

      //   // Open NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

      //   // Set Menu Items Only Icon Mode
      //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

      //   // Menu Action buttons
      //   this.clickNotClose   = true
      //   this.showCloseButton = false

      //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
      //   this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)
      // }
    },
    toggleReduce(val) {
      this.reduceButton = val
      this.setVerticalNavMenuWidth()
    }
  },
  mounted() {
    this.setVerticalNavMenuWidth();
    this.getNotesToRead();
  }
}

</script>


<style lang="scss">
@import "@sass/vuexy/components/verticalNavMenu.scss"
</style>

<style>
.v-nav-menu .scroll-area-v-nav-menu .con-vs-chip.no-box-shadow {
  box-shadow: none;
}

.v-nav-menu .scroll-area-v-nav-menu a.router-link-exact-active .con-vs-chip {
  background: none;
}

.theme-rg-semi-dark .v-nav-menu .shadow-bottom {
  background: linear-gradient(to bottom, #3c3c3c85 44%, rgba(83, 83, 84, 0.24) 73%, rgba(44, 48, 60, 0) 100%) !important;
}

div.vs-sidebar-group h4 {
  color: #fff !important;
  font-family: 'gilroyregular' !important;
  font-size: small !important;
  margin-top: 1rem !important;
}

.vs-sidebar-group {
  padding-left: 1rem !important;
}

ul.vs-sidebar--group-items {
  padding-right: 1rem !important;
}

.span-align {
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}
</style>
