<template>
  <div id="switch-account-popup">
    <vs-popup title="Cambiar cuenta" :active.sync="isSwitchAccountPopupActive">
      <vx-card>
        <vs-tabs v-model="activeTab" alignment="fixed" color="primary">
          <!-- CAMBIO DE CUENTA -->
          <vs-tab icon-pack="feather" icon="icon-file-text" label="Cambio de cuenta">
            <div class="vx-row" v-if="hasMoreThanOneOption">
              <div class="vx-col w-full">
                <h6>Selecciona la cuenta a la que quieres acceder:</h6>
              </div>
              <div class="vx-col w-full mb-5 mt-2">
                <ul class="centerx radio-display">
                  <li class="mt-5" v-for="role in userRolesOptions" :key="role.index">
                    <vs-radio v-model="currentRoleName" :vs-value="role.name"> Cuenta de {{role.label}}</vs-radio>
                  </li>
                </ul>
              </div>
            </div>
      
            <div class="vx-row" v-if="!hasMoreThanOneOption">
              <div class="vx-col w-full">
                <p>Confirma que deseas acceder a la siguiente cuenta:</p>
                <p class="mt-5 mb-2 bold">Cuenta de {{defaultRoleName}}</p>
              </div>
            </div>
  
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="flex flex-wrap">
                  <vs-button class="mt-5" @click.stop="confirmSwitch">Confirmar cambio</vs-button>
                </div>
              </div>
            </div>
  
          </vs-tab>
  
          <!-- CAMBIO DE PROGRAMA -->
          <vs-tab icon-pack="feather" icon="icon-file-text" v-if="programs.length > 1" label="Cambio de programa">
            <div>
              <div class="vx-col w-full">
                <h6>Selecciona al programa al que quieres acceder:</h6>
              </div>
              <div class="vx-col w-full mb-5 mt-2">
                <ul class="centerx radio-display">
                  <li class="mt-5" v-for="program in programs" :key="program.id">
                    <vs-radio v-model="currentProgramId" :vs-value="program.id"> Administrador de programa {{program.name}}</vs-radio>
                  </li>
                </ul>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap">
                    <vs-button class="mt-5" @click.stop="confirmProgramSwitch">Confirmar cambio</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>      
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import sessionsHelper from "@mixins/sessionsHelper";
export default {
  name: "SwitchAccountPopup",
  data() {
    return {
      currentRoleName: null,
      currentProgramId: null,
      userRolesOptions: [],
      activeTab: 0
    }
  },
  mixins: [sessionsHelper],
  mounted() {
    this.setCurrentRolesOptions();
  },
  computed: {
    ...mapState("auth", ["user"]),
    isSwitchAccountPopupActive: {
      get() {
        return this.$store.state.isSwitchAccountPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SWITCH_ACCOUNT_POPUP_ACTIVE", val);
      }
    },
    hasMoreThanOneOption(){
      return this.userRolesOptions.length > 1;
    },
    defaultRoleName(){
      if(this.userRolesOptions.length > 0){
        return this.userRolesOptions[0].label;
      }
    },
    programs(){
      if(this.programsInfo != undefined){
        return this.ProgramsInfo;
      }
      return [];
    }
  },
  methods: {
    ...mapMutations('extended_auth', ['set_new_extended_session','set_extended_session_token', 'set_extended_session_role', 'set_extended_session_user', 'setCurrentProgramData']),
    ...mapMutations('auth', ['setCurrentProgramData']),
    setCurrentRolesOptions(){
      this.user.valid_roles.forEach(f => {
        if(this.user.role !== f){
          const rr = this.StaticRolesOptions.find(r => r.name === f);
          this.userRolesOptions.push(rr);
        }
      });
      this.userRolesOptions.sort((a, b) => (a.index < b.index) ? -1 : 1);
      if(this.userRolesOptions.length > 0){
        this.currentRoleName = this.userRolesOptions[0].name;
      }
    },
    confirmProgramSwitch() {
      this.showLoading(true, "Cambiando de programa...");
      try {
        //se guarda el programa seleccionado
        let currentProgram = this.programs.find(x => x.id == this.currentProgramId);
        this.setCurrentProgramData(currentProgram);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
      this.showLoading(false)
    }, 
    async confirmSwitch(){
      this.isSwitchAccountPopupActive = false;
      this.showLoading(true, "Cambiando de cuenta...");
      try{
        const payload = { role_name: this.currentRoleName }
        const res = await axios.post('/api/auth/switch-role', payload);
        // eliminar datos de sesion extendida en caso de que exista
        this.cleanExtendedSession()

        // solicitar la informacion del usuario con el nuevo role
        const userRes = await this.getUserInfo(res.data.role);
        // verificar si debe almacenarse la sesion extendida, usando el token actual
        this.checkForExtendedSession(this.AccessToken, userRes.data);
        // recargar la pagina para cargar las caracteristicas del rol especifico
        window.location = "/";
      }
      catch (e) {
        this.warn(e)
        let m = "";
        if(e && e.response && e.response.data && e.response.data.message){
          m = e.response.data.message;
        }
        else {
          m = "Ha ocurrido un error, intenta más tarde."
        }
        this.errorNotifDialog("Error en la operación", m);
      }
      this.stopLoading()
    },
    checkForExtendedSession(token, user){
      if(this.roleShouldHaveExtendedSession(user.role)){
        // almacenar el token y los datos de la sesion en extended_auth (localStorage)
        this.set_new_extended_session(user.user_id);
        this.set_extended_session_token(token);
        this.set_extended_session_role(user.role);
        this.set_extended_session_user(user);
      }
    },
  }
}
</script>

<style scoped>

</style>